<template>
    <div class="card card-primary card-outline" v-if="servicio && servicio.eventos?.boton" >
        <div class="card-body box-profile">
            <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item" style="border:none">
                    <label @click="console.log(servicio)">
                       Ejecutar eventos
                    </label>
                    <div style="display: grid; gap: 10px;">
                        <button v-for="boton in botones" :key="boton.id" class="btn btn-primary" @click="accion(boton)">{{ boton.nombre }}</button>
                    </div>
                </li>
            </ul>
        </div>
    </div>



</template>

<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio'],
    components: {
    },
    setup() {
        return; 
    },
    data() {
        return {
            botones:[],
        }
    },
    methods: {
        async accion(boton){
            const api = new PwgsApi();
            try{
                await api.put('servicios/'+this.id+'/ejecutar-evento-boton', {id_evento:boton.id});
                this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Acción realizada correctamente', life: 2000 });
            }catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'e', life: 2000 });
            }
        },
        llenarBotones(cual){
            console.log('entra', cual);
            if(this.servicio){
                if(this.servicio.eventos?.boton){
                    this.botones = this.servicio.eventos.boton;
                }else{
                    console.log('No hay botones');
                    this.botones = [];
                }
            }
        }
    },

    activated() {
        this.llenarBotones('1');
    },
    
    watch: {
        servicio(){
            this.llenarBotones('2');
        },
        id(){
            this.llenarBotones('3');
        }  
    }
})
</script>